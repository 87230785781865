import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import {RouterOutlet} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ComponentsModule} from "./component/components.module";

@NgModule({
  declarations: [
    AppComponent,
    // MemeComponent,
    // SearchComponent,
    // TagComponent,
    // ContactComponent,
    // TermsPrivacyComponent,
    // NewsDetailComponent,
    // NewsListComponent,
    // NotFoundComponent,
    // ListNewsComponent,
    // ListNewsRelatedComponent,
    // ListMemeComponent,
    // ListMemeRelatedComponent,
    // ListShopeeComponent,
    // ListAdsComponent,
    // DownloadMemeComponent,
    // FbCommentsDirective,
    // SanitizeHtmlPipe
  ],
  imports: [
    CommonModule,
    RouterOutlet,
    HttpClientModule,
    BrowserModule.withServerTransition({appId: 'MemeChua'}),
    BrowserAnimationsModule,
    AppRoutingModule,
    ComponentsModule
  ],
  providers: [
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
