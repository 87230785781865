import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastrModule} from "ngx-toastr";
import {DownloadMemeComponent} from "./download-meme/download-meme.component";
import {ListAdsComponent} from "./list-ads/list-ads.component";
import {ListMemeComponent} from "./list-meme/list-meme.component";
import {ListMemeRelatedComponent} from "./list-meme-related/list-meme-related.component";
import {ListNewsComponent} from "./list-news/list-news.component";
import {ListNewsRelatedComponent} from "./list-news-related/list-news-related.component";
import {ListShopeeComponent} from "./list-shopee/list-shopee.component";
import {RouterModule} from "@angular/router";
import {HttpClientModule} from "@angular/common/http";
import {NgxSpinnerModule} from "ngx-spinner";
import {CarouselModule} from "ngx-owl-carousel-o";
import {SeoService} from "../services/seo.service";
import {SanitizeHtmlPipe} from "./pipe/sanitize-html.pipe";
import {FbCommentsDirective} from "./directive/fb-comments.directive";

@NgModule({
  declarations: [
    DownloadMemeComponent,
    ListAdsComponent,
    ListMemeComponent,
    ListMemeRelatedComponent,
    ListNewsComponent,
    ListNewsRelatedComponent,
    ListShopeeComponent,
    SanitizeHtmlPipe,
    FbCommentsDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    CarouselModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    ToastrModule,
    CarouselModule,
    DownloadMemeComponent,
    ListAdsComponent,
    ListMemeComponent,
    ListMemeRelatedComponent,
    ListNewsComponent,
    ListNewsRelatedComponent,
    ListShopeeComponent,
    SanitizeHtmlPipe,
    FbCommentsDirective
  ],
  providers: [
    SeoService
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class ComponentsModule {
}
