export const STORAGE_KEY = {
  ACCESS_TOKEN: 'access_token',
  USER_INFO: 'user_info',
};

export const STATUS_CODE = {
  SUCCESS: 200,
  ERROR: 201,
  UNAUTHORIZED: 401,
  INTERNAL_SERVER_ERROR: 500,
};

export const TAG_TYPE = {
  MEME: 'MEME',
  NEWS: 'NEWS',
  LOADING: 'LOADING',
}

export const PAGE_SIZE_DEFAULT = 15;
