import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NotFoundComponent} from "./pages/not-found/not-found.component";
import {TermsPrivacyComponent} from "./pages/terms-privacy/terms-privacy.component";
import {ContactComponent} from "./pages/contact/contact.component";
import {SearchComponent} from "./pages/search/search.component";
import {TagComponent} from "./pages/tag/tag.component";
import {MemeComponent} from "./pages/meme/meme.component";
import {HomeComponent} from "./pages/home/home.component";
import {NewsDetailComponent} from "./pages/news/news-detail/news-detail.component";
import {NewsListComponent} from "./pages/news/news-list/news-list.component";

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'meme', loadChildren: () => import('./pages/meme/meme.module').then(m => m.MemeModule) },
  { path: 'tag', loadChildren: () => import('./pages/tag/tag.module').then(m => m.TagModule) },
  { path: 'tin-tuc', loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule) },
  { path: 'search', loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule) },
  { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
  { path: 'terms-privacy', loadChildren: () => import('./pages/terms-privacy/terms-privacy.module').then(m => m.TermsPrivacyModule) },
  { path: 'not-found', loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule) },
  // Otherwise, redirect to not found page
  { path: '**', redirectTo: '/not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
