<div class="w-100">
  <div class="card card-block card-stretch mb-0">
    <div class="card-header d-flex justify-content-between">
      <div class="header-title">
        <h4 class="card-title fw-bold">Vozer Item</h4>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isMobile()">
  <div class="w-100">
    <div class="card card-block card-stretch mb-0">
      <div class="card-header">
        <div class="w-100">
          <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let slide of listShopee">
              <ng-template carouselSlide>
                <a [href]="slide.link" target="_blank" [title]="slide.name">
                  <div class="image-container w-100">
                    <img loading="lazy" [src]="slide.image" [alt]="slide.name" [title]="slide.name"
                         class="img-fluid rounded w-100">
                    <div class="overlay">
                    </div>
                  </div>
                </a>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!isMobile()">
  <div class="w-100" style="overflow-y: auto; max-height: 100vh" *ngFor="let item of listShopee">
    <a [href]="item.link" target="_blank" [title]="item.name">
      <div class="card card-block card-stretch mb-0">
        <div class="card-header">
          <div class="image-container w-100">
            <img loading="lazy" [src]="item.image" [alt]="item.name" [title]="item.name"
                 class="img-fluid rounded w-100">
            <div class="overlay">
              <div class="overlay-text">
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
